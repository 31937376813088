import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { BsPersonPlusFill } from 'react-icons/bs';
import DotSpinner from '../../components/DotSpinner';
import TextIcon from '../../components/TextIcon';
import InvolvedUserItem from './InvolvedUserItem';

interface IInvolvedUserAddingItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	projectId: number;
	isFetching: boolean;
	onInsertingChange: (newInserting: boolean) => void;
}
const InvolvedUserAddingItem = ({
	projectId,
	isFetching,
	disabled,
	onInsertingChange,
}: IInvolvedUserAddingItemProps) => {
	const [inserting, setInserting] = useState(false);

	const onInsertClick = useCallback(() => setInserting(true), []);

	const onInsertingClose = useCallback(() => setInserting(false), []);

	useEffect(() => {
		onInsertingChange(inserting);
	}, [inserting, onInsertingChange]);

	if (inserting)
		return <InvolvedUserItem disabled={disabled} onInsertingClose={onInsertingClose} projectId={projectId} />;
	return (
		<Col className="d-flex">
			{isFetching ? (
				<DotSpinner className="text-center m-auto">Завантаження інформації</DotSpinner>
			) : (
				<Button variant="outline-secondary" className="w-100 h-100" onClick={onInsertClick}>
					<TextIcon Icon={BsPersonPlusFill} size="lg" inline className="mt-n1">
						Залучити користувача
					</TextIcon>
				</Button>
			)}
		</Col>
	);
};

export default InvolvedUserAddingItem;
