import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS } from '../../common';
import DotSpinner from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import EntitySelector from '../../components/controls/EntitySelector';
import { IEntityBase } from '../../interfaces/entities';
import { TProjectContext } from '../../interfaces/projects';
import { removeNodeIfObsolete, upsertNode } from '../../schemeUtils';
import { useAppDispatch } from '../../store';
import { getControlData } from '../../utils';
import { changeNetworkStateTs } from '../projects/projectSlice';
import { useEditProjectTargetMutation, useProjectTargetsQuery } from '../services/project';
import ProjectEntityList from './projectEntityList';

interface IProjectTargetsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	projectId: number;
}
const ProjectTargets = ({ projectId, className, disabled }: IProjectTargetsProps) => {
	const [entities, setEntities] = useState<IEntityBase[]>();
	const { projectEntitiesIds } = useOutletContext<TProjectContext>();
	const { data, error, isFetching } = useProjectTargetsQuery(projectId, {
		refetchOnMountOrArgChange: true,
	});
	const [updateProjectTarget, updateProjectTargetResult] = useEditProjectTargetMutation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!data) return;
		setEntities(data);
	}, [data]);

	useEffect(() => {
		if (!updateProjectTargetResult) return;
		const { isError, error, isSuccess } = updateProjectTargetResult;
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		if (isSuccess) {
			dispatch(changeNetworkStateTs());
		}
	}, [updateProjectTargetResult]); // eslint-disable-line

	const onBtnRemoveCLick = useCallback(
		async (event: React.MouseEvent<HTMLDivElement>) => {
			const sEntityId = getControlData(event.target, '.btn-action', 'entityId');
			if (!sEntityId) return;
			const entityId = parseInt(sEntityId);
			if (!entityId) return;

			if (
				!(await showPromiseConfirm(
					"Ви впевнені, що хочете вилучити даний об'єкт з переліку цільових об'єктів проекту?"
				))
			)
				return;

			const result = await updateProjectTarget({
				projectId,
				entityId,
				method: DEFAULTS.httpMethod.delete,
			});
			if ((result as any).error) return;

			const entity = entities?.find((e) => e.id === entityId);
			if (!entity) return;
			upsertNode({ ...entity, is_init: false });
			removeNodeIfObsolete(entityId);
			setEntities((e) => e?.filter((e) => e.id !== entityId));
		},
		[entities, projectId, updateProjectTarget]
	);

	const onAddInitEntity = useCallback(
		async (newInitialEntity: IEntityBase) => {
			const entityExists = entities?.find((e) => e.id === newInitialEntity.id);
			if (entityExists) return;
			const result = await updateProjectTarget({
				projectId,
				entityId: newInitialEntity.id,
				method: DEFAULTS.httpMethod.post,
			});
			if ((result as any).error) return;
			upsertNode({ ...newInitialEntity, is_init: true });
			setEntities((e) => e?.concat(newInitialEntity));
		},
		[entities, projectId, updateProjectTarget]
	);

	return (
		<fieldset className={className}>
			<legend className="text-truncate">
				Цільові об'єкти
				<span className="text-muted text-small ms-2">(при додаванні/видаленні зміни зберігаються автоматично)</span>
			</legend>
			{error && <FetchError error={error} />}
			{/* {isFetching && <DotSpinner>Завантаження інформації</DotSpinner>} */}
			{entities && (
				<ProjectEntityList entities={entities} withActions={!disabled} onClick={onBtnRemoveCLick} disabled={disabled}>
					{isFetching && <DotSpinner>Завантаження інформації</DotSpinner>}
					{!disabled && !isFetching && (
						<EntitySelector
							initialEntityId={0}
							fieldName="init_entity"
							className="align-self-center w-38"
							onEntitySelect={onAddInitEntity}
							projectEntitiesIds={projectEntitiesIds}
							withAddButton
							required={false}
							clearAfterSelect
						/>
					)}
				</ProjectEntityList>
			)}
		</fieldset>
	);
};

export default ProjectTargets;
