import dayjs from 'dayjs';
import { TObjectType, TObjectWithMandatoryFields, TStringOrNull } from './interfaces/common';

/**
 * Определяет, изменились ли значения полей объекта target по сравнению с объектом source.
 * Ключи перебираются по объекту source
 * Если источника нет, то считаем, что объекты различаются
 * @param source Исходный объект
 * @param target Сравниваемый объект
 * @param strict Если равно true, то производит строгое сравнение, иначе - нет
 */
export const targetIsNotEquals = (source: TObjectType | undefined, target: TObjectType) =>
	source === undefined || Object.keys(source).some((key) => source[key] !== target[key]);

/**
 * Повертає об'єкт розбіжностей між двома об'єктами для передачі на сервер.
 * Примітка: в БД логічний тип представлено значеннями 1 та 0.
 * @param source Исходный объект
 * @param target Объект после редактирования
 * @param REQUIRED_FIELDS Названия полей, которые обязательно быть включены в пакет на отправку
 */
export const getDataForSave = <T extends TObjectType, Q extends keyof T>(
	source: T | undefined,
	target: T,
	REQUIRED_FIELDS: Q[] = [],
	nullIfEmpty = true
): TObjectWithMandatoryFields<T, Q> => {
	const result = { ...target };
	if (source !== undefined) {
		Object.keys(source).forEach((key) => {
			if (!REQUIRED_FIELDS.includes(key as Q) && source[key] === result[key]) delete result[key];
		});
	}
	if (nullIfEmpty)
		for (const key in result) {
			if (result[key] === '') (result[key] as any) = null;
		}
	return result;
};

/**
 * Виконує неглибоке порівняння двох об'єктів
 * @param etalon Вихідний об'єкт
 * @param target Кінцевий об'єкт
 * @returns Якщо об'єкти однакові, то повертається True, інакше False
 */
export const objectsAreEquals = <T extends TObjectType, Q extends keyof T>(
	etalon: T,
	target?: Partial<T>,
	omit: Q[] = []
) =>
	target ? Object.entries(etalon).every(([key, value]) => omit.includes(key as Q) || target[key] === value) : false;

export const getControlData = <T extends HTMLElement>(
	target: EventTarget,
	controlSelector: string,
	dataName: string
) => {
	const control = (target as HTMLElement).closest(controlSelector) as T;
	if (!control) return;
	return control.dataset[dataName];
};

export const isPeriodValid = (startDate: string, endDate: string) => startDate && endDate && startDate <= endDate;

/**
 * Разбивает текст на строки желаемой длины по пробелам или тире.
 * Если какое-то слово больше желаемой длины, то оно не усекается и занимает целую строку.
 * @param text текст для разбиения на строки
 * @param maxLength максимальная длина строки
 * @returns массив строк требуемой длины.
 */
export const splitTextByLength = (text: string, maxLength = 25, decorator = '') => {
	const words = text
		.replace(/\\x0b/gi, '')
		.replace(/\xa0/gi, ' ')
		.split(/(?<=[-–—\s])/i)
		.filter((word) => word.trim() !== '');

	let lines: string[] = [];
	let current = '';
	for (const word of words) {
		const newCurrent = current + word;
		if (newCurrent.trim().length <= maxLength) {
			current = newCurrent;
		} else {
			current && lines.push(current);
			current = word;
		}
	}
	current && lines.push(current);

	return lines.map((s) => `${decorator}${s.trim()}${decorator}`).join('\n');
};

/**
 * The function `getEntityLogoSrc` returns the source of an entity logo based on the input source and
 * type.
 * @param {TStringOrNull | undefined} src - The `src` parameter is a string that represents the source
 * of the entity logo image. It can be a data URL starting with 'data:image' or a relative path to the
 * image file in the `/assets/objects/` directory. It can also be `undefined`.
 * @param {number} [type=0] - The `type` parameter in the `getEntityLogoSrc` function is a number that
 * represents the type of entity. It has a default value of 0 if not provided when calling the
 * function.
 * @returns The function `getEntityLogoSrc` returns a string value. If the `src` parameter is not
 * undefined and starts with 'data:image', it returns the `src` value as is. Otherwise, it returns
 * `/assets/objects/` if `src` is defined. If `src` is undefined, it returns
 * `/img/entityType/.svg`.
 */
export const getEntityLogoSrc = (src: TStringOrNull | undefined, type: number = 0) => {
	return src ? (src.startsWith('data:image') ? src : `/assets/objects/${src}`) : `/img/entityType/${type}.svg`;
};

export const getRelationshipImageSrc = (src: string) =>
	src.startsWith('data:image') ? src : `/assets/relationships/${src}`;

/**
 * The `loadImage` function creates a hidden link element in the document body to download an image
 * specified by the provided URL and name.
 * @param {string} href - The `href` parameter is a string that represents the URL of the image that
 * you want to download.
 * @param {string} name - The `name` parameter is a string that represents the desired filename for the
 * downloaded image.
 */
export const loadImage = (href: string, name: string) => {
	const link = document.createElement('a');
	link.download = name;
	link.style.opacity = '0';
	document.body.append(link);
	link.href = href;
	link.click();
	link.remove();
};
/**
 * Повертає інформацію про те, чи містять масиві різні (саме так) елементи.
 * Зверніть увагу на те, що порядок елементів в масивах не має значення.
 * @param array1 Масив 1
 * @param array2 Масив 2
 * @returns Якщо вміст масивів різний, то повертається True
 */
export const differentContentInArray = <T>(array1: T[], array2: T[]) => {
	if (array1.length !== array2.length) return true;
	return array1.some((item) => !array2.includes(item));
};

export const loadFileToImageControl = (fileControl: HTMLInputElement | null) =>
	new Promise<string>((response, reject) => {
		if (!fileControl) return reject('Відсутній елемент зображення');
		const reader = new FileReader();
		reader.onload = () => response(reader.result as string);

		const files = fileControl.files;
		if (!files || files.length === 0) return reject('Файл зображення не обрано');
		const [file] = files;

		reader.readAsDataURL(file);

		fileControl.value = ''; // Очистка файла

		// fr.readAsArrayBuffer(file)
		// fr.onload = function() {
		//     // you can keep blob or save blob to another position
		//     const blob = new Blob([fr.result])

		//     // url for download
		//     const url = URL.createObjectURL(blob, {type: "image/png"});
		//     const a = document.createElement("a")
		//     a.href = url
		//     a.download = "image"
		//     a.click()
		// }
	});

export const getRegexpForString = (s: string) => {
	const VOWEL_LETTERS = 'йуеіаоєяиыэї';
	const VOWEL_REGEXP = '[йуеіаоєяиыэїЙУЕІАОЄЯИЫЭЇ]';
	const str = s
		.toLowerCase()
		.split('')
		.map((c) => (VOWEL_LETTERS.includes(c) ? VOWEL_REGEXP : `[${c}${c.toUpperCase()}]`))
		.join('');
	return new RegExp(str);
};

/**
 * The function `getHumanDigitalDuration` takes a number of seconds as input and returns a formatted
 * duration in hours, minutes, and seconds.
 * @param {number} seconds - The `seconds` parameter is a number representing the duration in seconds
 * that you want to convert into a human-readable digital format.
 * @returns The function `getHumanDigitalDuration` returns a formatted duration based on the input
 * number of seconds. If the input is `Infinity`, it returns the string `'0:00'`. Otherwise, it uses
 * the `dayjs` library to create a duration object and then formats it as either minutes and seconds
 * (`'m:ss'`) if the duration is less than an hour, or hours, minutes
 */
export const getHumanDigitalDuration = (seconds: number) => {
	if (seconds === Infinity) return '0:00';
	const duration = dayjs.duration(seconds, 's');
	return duration.format(duration.hours() === 0 ? 'm:ss' : 'H:mm:ss');
};
