import classNames from 'classnames';
import React from 'react';
import { IconBaseProps, IconType } from 'react-icons';

interface ITextIconProps extends React.HTMLAttributes<HTMLDivElement> {
	Icon: IconType;
	iconLast?: boolean;
	size?: 'lg';
	nowrap?: boolean;
	center?: boolean;
	containerClassName?: string;
	/**
	 * * Параметр inline краще не використовувати у зв'язку з дещо дивним вирівнюванням
	 * * елементів з svg при використанні d-inline-flex
	 */
	inline?: boolean;
	containerTitle?: string;
}
const TextIcon = ({
	Icon,
	className,
	children,
	iconLast = false,
	size,
	nowrap = false,
	center = false,
	containerClassName,
	inline = false,
	containerTitle,
	...rest
}: ITextIconProps) => {
	const isLarge = size === 'lg';
	if (!children) return <Icon className={classNames(className, isLarge && 'icon-lg')} {...(rest as IconBaseProps)} />;
	return !inline ? (
		<span
			title={containerTitle}
			className={classNames(
				inline ? 'd-inline-flex' : 'd-flex',
				'align-items-center',
				center && 'justify-content-center',
				nowrap && 'text-nowrap',
				isLarge ? 'gap-2' : 'gap-1',
				containerClassName
			)}
		>
			<Icon
				className={classNames(className, 'flex-shrink-0', isLarge && 'icon-lg', iconLast && 'order-1')}
				{...(rest as IconBaseProps)}
			/>
			{children}
		</span>
	) : (
		<>
			{iconLast && children}
			<Icon
				className={classNames(
					className,
					isLarge && 'icon-lg',
					iconLast ? `ms-${isLarge ? 2 : 1}` : `me-${isLarge ? 2 : 1}`
				)}
				{...(rest as IconBaseProps)}
			/>
			{!iconLast && children}
		</>
	);
};

export default TextIcon;
