import React, { useCallback, useEffect, useRef } from 'react';
import { Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import { BsPersonDashFill, BsSave, BsXLg } from 'react-icons/bs';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS } from '../../common';
import Asterisk from '../../components/Asterisk';
import TextIcon from '../../components/TextIcon';
import { useFormFields } from '../../hooks';
import { IProjectShare } from '../../interfaces/projects';
import { useEditProjectShareMutation } from '../services/project';

const DEFAULT_INVOLVED_USER: IProjectShare = {
	email: '',
	is_editor: true,
};

interface IInvolvedUserItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	projectId: number;
	user?: IProjectShare;
	serial_no?: number;
	onInsertingClose?: () => void;
}
const InvolvedUserItem = ({ projectId, user, disabled, serial_no, onInsertingClose }: IInvolvedUserItemProps) => {
	const inserting = user === undefined;
	// const [initialData, setInitialData] = useState<IProjectShare>(user || DEFAULT_INVOLVED_USER);
	const { formFields, createChangeHandler, modified } = useFormFields(user || DEFAULT_INVOLVED_USER);
	const [updateProjectShare, updateProjectShareResult] = useEditProjectShareMutation();
	const refFirstControl = useRef<HTMLInputElement>(null);
	const readonly = disabled || !inserting;

	useEffect(() => {
		if (readonly) return;
		refFirstControl.current?.focus();
	}, [readonly]);

	// useEffect(() => {
	// 	if (!user) return;
	// 	setInitialData({ ...user });
	// }, [user]);

	useEffect(() => {
		if (!updateProjectShareResult) return;
		const { isError, error, isSuccess } = updateProjectShareResult;
		if (isSuccess) {
			if (onInsertingClose) onInsertingClose();
			return;
		}
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateProjectShareResult, onInsertingClose]);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (inserting) {
			updateProjectShare({ projectId, user: { ...formFields }, method: DEFAULTS.httpMethod.post });
		} else {
			if (!(await showPromiseConfirm(`Ви впевнені, що хочете вилучити користувача ${user.email} з проекту?`))) return;
			updateProjectShare({ projectId, user: { ...formFields }, method: DEFAULTS.httpMethod.delete });
		}
	};

	const onReset = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			event.stopPropagation();
			if (modified && !(await showPromiseConfirm('Ви впевнені, що хочете скасувати додавання?')))
				return setTimeout(() => refFirstControl.current?.focus(), 200);
			if (onInsertingClose) onInsertingClose();
		},
		[onInsertingClose, modified]
	);

	return (
		<Col>
			<Card>
				<Card.Body>
					<Form onSubmit={onSubmit} onReset={onReset} id={`fmInvolvedUser_${serial_no}`}>
						<Form.Group as={Row} className="g-1">
							<Form.Label column xs="auto" htmlFor={`eInvolvedUserEmail_${serial_no}`} className="me-1">
								Email
								{inserting && <Asterisk />}
							</Form.Label>
							<Col>
								<Form.Control
									id={`eInvolvedUserEmail_${serial_no}`}
									type="email"
									required
									placeholder="Введіть електронну адресу"
									maxLength={50}
									value={formFields.email}
									onChange={createChangeHandler('email')}
									autoComplete="off"
									ref={refFirstControl}
									disabled={readonly || updateProjectShareResult.isLoading}
								/>
							</Col>
						</Form.Group>
						<div className="d-flex justify-content-between align-items-center mt-2">
							<Form.Check
								type="switch"
								label="Редагування"
								id={`InvolvedUserIsEditor_${serial_no}`}
								checked={formFields.is_editor}
								onChange={createChangeHandler('is_editor', 'boolean')}
								disabled={readonly || updateProjectShareResult.isLoading}
							/>
							{readonly ? (
								!disabled && (
									<Button
										size="sm"
										type="submit"
										variant="outline-danger"
										disabled={updateProjectShareResult.isLoading}
									>
										<TextIcon
											Icon={BsPersonDashFill}
											className={updateProjectShareResult.isLoading ? 'animation-bounce' : undefined}
										>
											Вилучити
										</TextIcon>
									</Button>
								)
							) : (
								<ButtonGroup>
									<Button
										size="sm"
										variant="outline-secondary"
										type="reset"
										disabled={updateProjectShareResult.isLoading}
									>
										<TextIcon Icon={BsXLg}>Закрити</TextIcon>
									</Button>
									<Button
										size="sm"
										variant="outline-primary"
										disabled={updateProjectShareResult.isLoading || !modified}
										type="submit"
									>
										<TextIcon
											Icon={BsSave}
											className={updateProjectShareResult.isLoading ? 'animation-bounce' : undefined}
										>
											Зберегти
										</TextIcon>
									</Button>
								</ButtonGroup>
							)}
						</div>
					</Form>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default InvolvedUserItem;
