import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION, TOrigin } from '../../common';
import Asterisk from '../../components/Asterisk';
import CommonSelector from '../../components/CommonSelector';
import DialogFooter from '../../components/DialogFooter';
import { FixedDotSpinner } from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import TextIcon from '../../components/TextIcon';
import DateWithNull from '../../components/controls/DateWithNull';
import DescriptionControl from '../../components/controls/DescriptionControl';
import WithUnits, { IWithUnits } from '../../components/hoc/withUnits';
import { useFormFields } from '../../hooks';
import { IProjectBase, TProjectContext } from '../../interfaces/projects';
import { getDataForSave } from '../../utils';
import DangerousListItem from '../dangerous/DangerousListItem';
import DangerousZone from '../dangerous/DangerousZone';
import { useEditProjectDataMutation, useProjectDataQuery } from '../services/project';
import InvolvedUsersList from './InvolvedUsersList';
import ProjectTargets from './ProjectTargets';

const DEFAULT_PROJECT_DATA: IProjectBase = {
	id: 0,
	title: 'Проект Фотона',
	creator_id: 0,
	initiator_id: 0,
	start_date: dayjs().format('YYYY-MM-DD'),
	end_date: null,
	unit_id: 0,
	description: '',
	creator_email: '',
};

interface IProjectDialogProps extends IWithUnits, React.AllHTMLAttributes<HTMLDivElement> {}
const ProjectDialog = ({ units }: IProjectDialogProps) => {
	const projectContext = useOutletContext<TProjectContext | undefined>();
	const projectId = projectContext?.projectId ?? 0;
	const inserting = projectId === 0;
	const { data, error: dataError, isFetching: dataIsFetching } = useProjectDataQuery(projectId || skipToken);
	const [initialData, setInitialData] = useState(() => DEFAULT_PROJECT_DATA);
	const { formFields, createChangeHandler, modified, createSetHandler } = useFormFields(initialData);
	const { state: referer } = useLocation();
	const navigate = useNavigate();
	const [updateProject, updateProjectResult] = useEditProjectDataMutation();
	const [involvedUserInserting, setInvolvedUserInserting] = useState(false);

	useEffect(() => {
		if (!data) return;
		setInitialData(data.projectBase);
	}, [data]);

	const disabled = !inserting && (!data?.rights.is_meta_editor || dataIsFetching);
	const someModified = inserting || modified;

	const closeDialog = useCallback(() => {
		const state = referer as TOrigin;
		if (state?.origin === 'basic') navigate(-1);
		else navigate('..');
	}, [referer, navigate]);

	const handleClose = useCallback(async () => {
		if ((someModified || involvedUserInserting) && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	}, [someModified, closeDialog, involvedUserInserting]);

	const onDeleteClick = useCallback(async () => {
		if (!(await showPromiseConfirm('Ви впевнені, що хочете видалити проект?'))) return;
		await updateProject({ id: projectId, method: DEFAULTS.httpMethod.delete });
	}, [projectId, updateProject]);

	const onInvolvedUserInsertingChange = useCallback((newStatus: boolean) => setInvolvedUserInserting(newStatus), []);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const dataToSave = getDataForSave(inserting ? undefined : initialData, formFields, ['id']);
		// console.log(dataToSave);
		updateProject({
			...dataToSave,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	useEffect(() => {
		if (!updateProjectResult) return;
		const { isError, error, isSuccess, data } = updateProjectResult;
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		if (isSuccess) {
			if (data.action === 'insert')
				return navigate(`/${DEFAULTS.routes.project}/${data.id}/${DEFAULTS.routes.data}`, { replace: true });
			if (data.action === 'delete') return navigate('/');
			closeDialog();
		}
	}, [updateProjectResult, closeDialog, navigate]);

	return (
		<Modal show centered size="xl" fullscreen="xl-down" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					{inserting ? <>Створення нового проекту</> : <>Редагування установчої інформації про проект</>}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="mh-50 overflow-auto">
				{dataError && <FetchError error={dataError} />}
				<Form className="row g-2" id="fmProject" onSubmit={onSubmit}>
					<Form.Group as={Col} sm={8} controlId="eTitle">
						<Form.Label>
							Назва
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="search"
							required
							placeholder="Введіть назву проекта"
							maxLength={100}
							value={formFields.title}
							onChange={createChangeHandler('title')}
							autoComplete="off"
							// ref={refFirstControl}
							disabled={disabled}
						/>
					</Form.Group>
					<CommonSelector
						onItemChange={createSetHandler('initiator_id', 'number')}
						selectedId={formFields.initiator_id}
						allItems={units.entities}
						label="Ініціатор"
						required
						fieldName="initiator_id"
						labelName="code"
						valueName="id"
						containerClassName="col-sm-4"
						placeholder="підрозділ-ініціатор запиту..."
						disabled={disabled}
					/>
					{!inserting && (
						<Col xs={12}>
							<Row className="g-2">
								<Form.Group as={Col} xs={6} md="auto" controlId="eStartDate">
									<Form.Label>Створено</Form.Label>
									<Form.Control type="date" disabled value={formFields.start_date} autoComplete="off" />
								</Form.Group>
								<Form.Group as={Col} xs={6} md="auto" controlId="eEndDate">
									<Form.Label>Завершено</Form.Label>
									<DateWithNull
										disabled={dataIsFetching || !data?.rights.is_manager}
										value={formFields.end_date || ''}
										onChange={createChangeHandler('end_date')}
										onClear={createSetHandler('end_date')}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="eCreatorEmail">
									<Form.Label>Автор</Form.Label>
									<Form.Control disabled value={formFields.creator_email} autoComplete="off" />
								</Form.Group>
								<Form.Group as={Col} controlId="eCreatorUnit">
									<Form.Label>Підрозділ</Form.Label>
									<Form.Control disabled value={units.entities[formFields.unit_id]?.code || ''} autoComplete="off" />
								</Form.Group>
							</Row>
						</Col>
					)}
					<DescriptionControl
						controlId="pDescription"
						controlClassName="col-12"
						value={formFields.description || ''}
						onChange={createChangeHandler('description')}
						placeholder="Введіть опис проекта"
						disabled={disabled}
						rows={5}
						onValueChange={createSetHandler('description')}
					/>
				</Form>
				{!inserting && <ProjectTargets projectId={projectId} className="mt-2" disabled={disabled} />}
				{!inserting && (
					<InvolvedUsersList
						projectId={projectId}
						className="mt-2"
						disabled={disabled}
						onInsertingChange={onInvolvedUserInsertingChange}
						isMetaEditor={data?.rights.is_meta_editor}
					/>
				)}
				{!inserting &&
					data?.rights.is_meta_editor &&
					projectContext?.projectEntitiesIds.size === 0 &&
					projectContext.projectRelationshipsIds.size === 0 && (
						<DangerousZone className="mt-2">
							<DangerousListItem onClick={onDeleteClick} title="Видалити проект." description={DELETION_WARNING}>
								<TextIcon Icon={BsTrash}>Видалити</TextIcon>
							</DangerousListItem>
						</DangerousZone>
					)}
				{dataIsFetching && <FixedDotSpinner>Завантаження інформації про проект</FixedDotSpinner>}
			</Modal.Body>
			<DialogFooter
				formId="fmProject"
				entityId={inserting ? undefined : projectId}
				disabled={!someModified || updateProjectResult.isLoading}
				isLoading={updateProjectResult.isLoading}
				onClose={handleClose}
			/>
		</Modal>
	);
};

export default WithUnits(ProjectDialog);
