import React from 'react';
import { Row } from 'react-bootstrap';
import FetchError from '../../components/FetchError';
import { useProjectShareQuery } from '../services/project';
import InvolvedUserAddingItem from './InvolvedUserAddingItem';
import InvolvedUserItem from './InvolvedUserItem';

interface IInvolvedUsersListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	projectId: number;
	onInsertingChange: (newInserting: boolean) => void;
	isMetaEditor?: boolean;
}
const InvolvedUsersList = ({
	projectId,
	className,
	disabled,
	onInsertingChange,
	isMetaEditor,
}: IInvolvedUsersListProps) => {
	const { data: involvedUsers, error, isFetching } = useProjectShareQuery(projectId);

	if (involvedUsers?.length === 0 && !isMetaEditor) return null;

	return (
		<fieldset className={className}>
			<legend className="text-truncate mb-0">
				Залучені користувачі
				<span className="text-muted text-small ms-2">(при додаванні/видаленні зміни зберігаються автоматично)</span>
			</legend>
			{error && <FetchError error={error} />}
			{involvedUsers && (
				<Row xs={1} sm={2} lg={3} className="g-2">
					{involvedUsers.map((user, index) => (
						<InvolvedUserItem
							key={user.email}
							user={user}
							disabled={disabled}
							serial_no={index}
							projectId={projectId}
						/>
					))}
					{!disabled && (
						<InvolvedUserAddingItem
							projectId={projectId}
							isFetching={isFetching}
							onInsertingChange={onInsertingChange}
						/>
					)}
				</Row>
			)}
		</fieldset>
	);
};

export default InvolvedUsersList;
