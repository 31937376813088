import { IdType } from 'vis-network/standalone';
import { DEFAULTS, TLinkAction } from '../../common';
import {
	IDictionary,
	IEntity,
	IEntityEditMethod,
	IInvalidatedTags,
	IUpdateResponse,
	QUERY_TAGS,
	TMutatingRow,
} from '../../interfaces/common';
import { IEntityBase, IEntityListItem, TEntityAlternativeTitles } from '../../interfaces/entities';
import {
	IProjectBase,
	IProjectContent,
	IProjectData,
	IProjectSearchParams,
	IProjectShare,
	IRecentProject,
} from '../../interfaces/projects';
import { IRelationshipListItem } from '../../interfaces/relationship';
import { photonApi } from './photonApi';

type TMutatingProject = TMutatingRow<IProjectBase, 'id'>;

export interface ISearchProjectResult {
	projects: IRecentProject[];
	total?: number;
}

export interface IProjectLinkParams {
	projectId: number;
	relationshipId: string;
	relationships?: IdType[];
}
export interface IProjectLinkResult {
	action: TLinkAction;
	relationshipId?: string;
	relationships?: IdType[];
}

export interface IEditProjectTargetParams {
	projectId: number;
	entityId: number;
}

export interface IEditProjectShareParams {
	projectId: number;
	user: IProjectShare;
}

export interface ISummaryEntityRelationship {
	relationshipId: string;
	directOrder: boolean;
}
export interface ISummaryEntity extends IEntityListItem {
	alternativeTitles: TEntityAlternativeTitles;
}
export interface IProjectSummary {
	entities: IEntity<number, ISummaryEntity>;
	relationships: IDictionary<string, IRelationshipListItem & { images: string[] }>;
	entityRelationships: IDictionary<number, ISummaryEntityRelationship[] | undefined>;
}

const projectApi = photonApi.injectEndpoints({
	endpoints: (builder) => ({
		recentProjects: builder.query<IRecentProject[], void>({
			query: () => 'project/recent',
			providesTags: (result) =>
				[{ type: QUERY_TAGS.project, id: 'list' } as IInvalidatedTags].concat(
					result?.map(({ project_id }) => ({ type: QUERY_TAGS.project, id: project_id })) ?? []
				),
		}),
		searchProjects: builder.query<ISearchProjectResult, IProjectSearchParams>({
			query: (params) =>
				`project/search/${encodeURIComponent(JSON.stringify({ ...params, perPage: DEFAULTS.projectsPerPage }))}`,
			// query: (params) => ({ url: 'project/search', method: DEFAULTS.httpMethod.get, body: params }),
			// providesTags: [{ type: QUERY_TAGS.project, id: 'list' }],
			providesTags: (result) =>
				[{ type: QUERY_TAGS.project, id: 'list' } as IInvalidatedTags].concat(
					result?.projects.map(({ project_id }) => ({ type: QUERY_TAGS.project, id: project_id })) ?? []
				),
		}),
		projectData: builder.query<IProjectData, number>({
			query: (id) => `project/${id}/data`,
			providesTags: (result, error, id) => [{ type: QUERY_TAGS.project_data, id }],
		}),
		/**
		 ** В projectTargets не буде тегів (цей хук використовується один раз в діалоговому вікні
		 ** редагування даних проекту), але при використанні даного хуку має бути вказане
		 ** refetchOnMountOrArgChange: true.
		 */
		projectTargets: builder.query<IEntityBase[], number>({
			query: (id) => `project/${id}/targets`,
			// providesTags: (result, error, id) => [{ type: QUERY_TAGS.project, id }],
		}),
		editProjectTarget: builder.mutation<IUpdateResponse<number>, IEditProjectTargetParams & IEntityEditMethod>({
			query: ({ projectId, entityId, method }) => ({
				url: `edit/project/${projectId}/targets`,
				method,
				body: { entityId },
			}),
			invalidatesTags: (result, error, { projectId }) => [{ type: QUERY_TAGS.project, id: projectId }],
		}),
		projectShare: builder.query<IProjectShare[], number>({
			query: (id) => `project/${id}/shares`,
			providesTags: (result, error, id) => [{ type: QUERY_TAGS.project_share, id }],
		}),
		editProjectShare: builder.mutation<IUpdateResponse<number>, IEditProjectShareParams & IEntityEditMethod>({
			query: ({ projectId, user, method }) => ({
				url: `edit/project/${projectId}/shares`,
				method,
				body: user,
			}),
			invalidatesTags: (result, error, { projectId }) => [{ type: QUERY_TAGS.project_share, id: projectId }],
		}),
		projectContent: builder.query<IProjectContent, number>({
			query: (id) => `project/${id}/content`,
			providesTags: (result, error, id) => [{ type: QUERY_TAGS.project_content, id }],
		}),
		projectRelationships: builder.query<IdType[], number>({
			query: (id) => `project/${id}/relationships`,
			providesTags: (result, error, id) => [{ type: QUERY_TAGS.project_relationship, id }],
		}),
		relationshipAction: builder.mutation<IProjectLinkResult, IProjectLinkParams & IEntityEditMethod>({
			query: ({ method, projectId, relationshipId, relationships }) => ({
				url: `/project/${projectId}/relationship/${relationshipId}`,
				method,
				body: relationships,
			}),
			invalidatesTags: (result, error, { projectId }) => [
				{ type: QUERY_TAGS.project_relationship, id: projectId },
				{ type: QUERY_TAGS.project, id: projectId },
			],
			// invalidatesTags: (result, error, { method, projectId, relationshipId }) => [
			// 	{ type: QUERY_TAGS.relationship, id: 'list' },
			// 	{ type: QUERY_TAGS.project, id: projectId },
			// ],
		}),
		editProjectData: builder.mutation<IUpdateResponse<number>, TMutatingProject & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'edit/project',
				method,
				body: rest,
			}),
			invalidatesTags: (result, error, { method, id }) => [
				{ type: QUERY_TAGS.project, id: method === DEFAULTS.httpMethod.put ? id : 'list' },
				{ type: QUERY_TAGS.project_data, id },
			],
		}),
		projectSummary: builder.query<IProjectSummary, number>({
			query: (id) => `project/${id}/summary`,
		}),
	}),
});

export const {
	useRecentProjectsQuery,
	useSearchProjectsQuery,
	useProjectDataQuery,
	useProjectContentQuery,
	useProjectRelationshipsQuery,
	useRelationshipActionMutation,
	useEditProjectDataMutation,
	useProjectTargetsQuery,
	useEditProjectTargetMutation,
	useProjectSummaryQuery,
	useProjectShareQuery,
	useEditProjectShareMutation,
} = projectApi;
